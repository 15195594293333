.blog {
  background: $color-white;
  border-radius: 4px;
  border: 1px solid $color-great-white;

  &:hover {
    border-color: $color-slinky;
  }

  .date {
    min-width: 100px;
  }
}

.blog-page {
  header {
    min-height: 50vh;
  }

  .tailwind header {
    min-height: 400px;

    .splash-cover {
      height: 400px;
      padding-top: 0 !important;
    }
  }
}

.rekt-text {
  input#example-3 {
    border: 1px solid $color-slinky;
    border-radius: 4px;
    display: block;
    font-size: 72px;
    margin: auto;
    text-align: center;
    width: 100px;
  } 


  textarea {
    height: auto;
    font-size: 24px;
    font-family: $font-family;
    border-radius: 4px;
    border: 1px solid $color-slinky;
    text-align: center;
    width: 100%;
    margin-bottom: 32px;
  }

  .example-text {
    background: rgba(255, 118, 117, 0.4);
    color: $font-color;
    font-size: 64px;
    font-family: Helvetica, Arial, sans-serif;
    margin: 48px auto;
    position: relative;
    white-space: nowrap;

    .example-line {
      height: 100%;
      opacity: 0.75;
      position: absolute;
      top: 0;
      width: 2px;

      &.line-1 {
        background: $color-baby-blue;
      }

      &.line-2 {
        background: $color-dark-blue;
      }
    }
  }
}

#isometric-illusion {
  width: 672px;
  height: 800px;
}
.project {
  color: $color-white;
  background-position: center;
  background-size: cover;
  height: 250px;

  & div {
    opacity: 0;
    transition: opacity 500ms ease-out;

    * {
      color: $color-white;
    }
  }

  &:hover div {
    opacity: 1;
  }
}

.project-page, .blog-page, .not-found-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  header {
    background-color: $splash-bg;
    width: 100%
  }

  h1 {
    font-size: 32px;
    padding: 0 16px;
  }

  .splash-cover {
    height: 45vh;
  }

  @media only screen and (min-width: $breakpoint-md) {
    h1 {
      font-size: 50px;
      padding: 0 32px;
    }

    .splash-cover {
      height: 65vh;
    }
  }

  .body {
    
    img {
      margin: 48px auto;
      max-height: 600px;

      &.circle {
        border-radius: 50%;
        max-width: 60%;
      }
    }

    h3 {
      margin-top: 30px;
    }

    h4 {
      margin-top: 24px;
    }

    p, ul, li, small {
      strong, em, i, a {
        margin: 0 3px;
      }

      a {
        font-weight: bold;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    ul {
      margin: 8px auto;
    }

    li {
      list-style: disc;
      margin-left: 24px;
    }

    code {
      background: $color-pantera;
      border: 0;
      border-radius: 6px;
      white-space: pre;
      display: block;
      overflow: auto;
      max-width: 100%;
      margin: 24px auto;
      color: $color-koala;
      font-family: 'Source Code Pro', monospace;
      line-height: 20px;
      word-break: normal;

      &:not(.code-block) {
        padding: 4px;
      }

      &.code-block {
        span, pre {
          color: $color-koala;
        }
      }

      &.inline {
        margin: 0;
        padding-right: 32px;
      }
    }

    sub {
      bottom: 0;
      border: 0;
      border-radius: 6px;
      margin: auto 3px;
      font-size: 1em;
      padding: 3px;
      background: $color-pantera;
      color: $color-koala;
      font-family: 'Source Code Pro', monospace;
      white-space: pre;
    }

    .cp_embed_wrapper {
      margin: 48px auto;
    }
  }
}